import taskStatuses from '@/utils/constants/taskStatuses'
import { TASK_PRIMARY } from '@/utils/constants/tasks/taskPriorityTypes'
import router from '@/router'
import {
  ACKNOWLEDGE_FULLY_EXECUTED,
  CANCELED,
  CONTRACT_FULLY_EXECUTED,
  REQUEST_FULLY_EXECUTED,
  REVOKED
} from "../../utils/constants/taskStatuses";

export const ABILITY = ['assign_date', 'due_date', 'reminder', 'recurrence']
export const ABILITY_SUB = ['reminder', 'recurrence']
export const ABILITY_ASSIGN_DATE = 'assign_date'
export const ABILITY_DUE_DATE = 'due_date'
export const ABILITY_REMINDER = 'reminder'
export const ABILITY_RECURRENCE = 'recurrence'

export class TaskService {
  canTaskRecurrence(task) {
    let status = ![
      taskStatuses.REVOKED,
      taskStatuses.CANCELED,
      taskStatuses.CONTRACT_FULLY_EXECUTED,
      taskStatuses.ACKNOWLEDGE_FULLY_EXECUTED,
      taskStatuses.REQUEST_FULLY_EXECUTED,
    ].includes(task.status)

    return status && task.priority_type !== TASK_PRIMARY && !task.parent_task_id
  }

  taskSettingAbility(check = false, except = []){
    if (check) {
      return ABILITY.filter((el) => !except.includes(el))
    }

    return ABILITY
  }

  taskSettingAbilitySub(check = false, except = []){
    if (check) {
      return ABILITY_SUB.filter((el) => !except.includes(el))
    }

    return ABILITY_SUB
  }

  /**
   * @param {string} expectedType
   * @param {string} taskType
   * @param {Object} params
   */
  static async checkTypeAndRedirect(expectedType, taskType, params) {
    if (expectedType !== taskType) {
      let name = expectedType
      switch (taskType) {
        case 'Request documents':
          name = 'TaskRequest'
          break
        case 'Contracts':
          name = 'TaskContracts'
          break
        case 'Acknowledge':
          name = 'TaskAcknowledge'
          break
      }
      return await router.push({ name: name, params: params })
    }

    return true
  }
}
