<template>
  <div class="rejected-documents">
    <div class="rejected-documents__heading">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15.0004 13.5904L13.5904 15.0004L10.0004 11.4104L6.41039 15.0004L5.00039 13.5904L8.59039 10.0004L5.00039 6.41039L6.41039 5.00039L10.0004 8.59039L13.5904 5.00039L15.0004 6.41039L11.4104 10.0004L15.0004 13.5904Z" fill="#FF5257"/>
      </svg>
      Rejected Documents
    </div>
    <div class="rejected-documents__list">
      <div
        v-for="document in task.requested_document.filter(d => d.status === 'Rejected')"
        :key="document.id"
        class="rejected-documents__item">

        <span @click="download('origin', document.id)">{{ document.name }}</span>

        -

        <strong>Submitted:</strong>

        {{ formatDate(document.created_at) }}

        <button
          title="Rejection Reason"
          class="rejected-documents__reason"
          @click="$emit('showReason', document)">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.666 1.66797H3.33268C2.41602 1.66797 1.67435 2.41797 1.67435 3.33464L1.66602 18.3346L4.99935 15.0013H16.666C17.5827 15.0013 18.3327 14.2513 18.3327 13.3346V3.33464C18.3327 2.41797 17.5827 1.66797 16.666 1.66797ZM4.99935 7.5013H14.9993V9.16797H4.99935V7.5013ZM11.666 11.668H4.99935V10.0013H11.666V11.668ZM14.9993 6.66797H4.99935V5.0013H14.9993V6.66797Z" fill="#677A89"/>
          </svg>
        </button>

      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment/moment";
import {downloadFile} from "@/utils/files";

export default {
  name: "TaskPageRejectedDocuments",
  props: ['task'],
  methods: {
    formatDate(date) {
      return moment.utc(date).local().format('MM/DD/YYYY')
    },
    download(contentType, id) {
      const payload = {
        type: 'task',
        task_id: this.task.id,
      }

      if (id) {
        payload.requested_document_id = id;
      }

      this.loading = true
      this.$http.common.downloadSingleDocument(payload)
        .then((response) => {
          downloadFile(response, this.documentFullName)
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style scoped lang="scss">
.rejected-documents {

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__reason {
    background: none;
    border: none;
    outline: none;
    padding: 0 5px;
  }

  &__heading {
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba(28, 38, 47, 1);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  &__item {
    color: rgba(28, 38, 47, 1);

    & span {
      color: rgba(0, 129, 194, 1);
      text-decoration: underline;
      cursor: pointer;
    }

    & strong {
      color: rgba(103, 122, 137, 1);
      font-weight: 700;
      font-size: 12px;
    }

    & svg {
      cursor: pointer;

      &:hover {

        & path {
          fill: rgba(0, 129, 194, 1);
        }
      }
    }
  }
}
</style>
