<template>
    <div>
        <div class="task-page-documents__title">
            <svg v-if="icon === 'check-green'" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                 viewBox="0 0 18 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8.99984 0.666748C4.39984 0.666748 0.666504 4.40008 0.666504 9.00008C0.666504 13.6001 4.39984 17.3334 8.99984 17.3334C13.5998 17.3334 17.3332 13.6001 17.3332 9.00008C17.3332 4.40008 13.5998 0.666748 8.99984 0.666748ZM7.33317 13.1667L3.1665 9.00008L4.3415 7.82508L7.33317 10.8084L13.6582 4.48341L14.8332 5.66675L7.33317 13.1667Z"
                      fill="#0BBD99"/>
            </svg>
            <svg v-if="icon === 'sample'" xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18"
                 fill="none">
                <path
                    d="M8.66732 0.667969H2.00065C1.07565 0.667969 0.333984 1.41797 0.333984 2.33464V15.668C0.333984 16.593 1.07565 17.3346 2.00065 17.3346H12.0007C12.9257 17.3346 13.6673 16.593 13.6673 15.668V5.66797L8.66732 0.667969ZM12.0007 15.668H2.00065V2.33464H7.83398V6.5013H12.0007V15.668ZM9.50065 8.94297V14.8346H3.60898L5.37565 13.068L3.01732 10.7096L5.37565 8.3513L7.73398 10.7096L9.50065 8.94297Z"
                    fill="#0068AD"/>
            </svg>
            <div class="d-flex align-items-center" v-if="upload">
                <svg v-if="uploadedFile" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                     fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8.99984 0.666748C4.39984 0.666748 0.666504 4.40008 0.666504 9.00008C0.666504 13.6001 4.39984 17.3334 8.99984 17.3334C13.5998 17.3334 17.3332 13.6001 17.3332 9.00008C17.3332 4.40008 13.5998 0.666748 8.99984 0.666748ZM7.33317 13.1667L3.1665 9.00008L4.3415 7.82508L7.33317 10.8084L13.6582 4.48341L14.8332 5.66675L7.33317 13.1667Z"
                          fill="#0BBD99"/>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M9.99935 1.66797C5.39935 1.66797 1.66602 5.4013 1.66602 10.0013C1.66602 14.6013 5.39935 18.3346 9.99935 18.3346C14.5993 18.3346 18.3327 14.6013 18.3327 10.0013C18.3327 5.4013 14.5993 1.66797 9.99935 1.66797ZM8.33268 14.168L4.16602 10.0013L5.34102 8.8263L8.33268 11.8096L14.6577 5.48464L15.8327 6.66797L8.33268 14.168Z"
                          fill="#677A89"/>
                </svg>
            </div>
            {{ title }}
        </div>

        <div v-if="upload" @dragover="uploadFileAreaHovered = true" @dragleave="uploadFileAreaHovered = false"
             :class="['task-page-documents__area', `task-page-documents__area_${ color }`, 'task-page-documents__area_upload']">

            <span v-if="uploadedFile" class="task-page-documents__upload-link">
                {{ uploadedFile.name }}
            </span>

            <div v-else class="task-page-documents__upload-input-placeholder"
                 :class="[{ 'task-page-documents__upload-input-placeholder_inactive': uploadFileAreaHovered }]">
                Drag & Drop File Here or
                <span class="task-page-documents__upload-link" @click="$refs.fileUpload.click()">
                    Browse File
                </span>
            </div>

            <input v-if="!uploadedFile" ref="fileUpload" type="file" class="task-page-documents__upload-input"
                   @change="onFileChange"/>
            <svg v-if="uploadedFile" @click="uploadedFile = null" class="task-page-documents__upload-input-delete"
                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8.46 11.88L9.87 10.47L12 12.59L14.12 10.47L15.53 11.88L13.41 14L15.53 16.12L14.12 17.53L12 15.41L9.88 17.53L8.47 16.12L10.59 14L8.46 11.88ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
                      fill="#FF1F26"/>
            </svg>
        </div>

        <div v-else :class="['task-page-documents__area', `task-page-documents__area_${ color }`]">
            <span v-if="documentName" :title="documentName" class="task-page-documents__link"
                  @click="$emit('linkClicked')">
                {{ documentName }}
            </span>
            <span v-else> No files available </span>
            <svg v-if="canDelete" class="task-page-documents__upload-input-delete" xmlns="http://www.w3.org/2000/svg"
                 width="24" height="24" viewBox="0 0 24 24" fill="none" @click="$emit('delete')">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8.46 11.88L9.87 10.47L12 12.59L14.12 10.47L15.53 11.88L13.41 14L15.53 16.12L14.12 17.53L12 15.41L9.88 17.53L8.47 16.12L10.59 14L8.46 11.88ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
                      fill="#FF1F26"/>
            </svg>
        </div>

        <div class="task-page-documents__buttons">
            <slot></slot>

            <CButton v-if="upload" :disabled="!uploadedFile" class="d-block" color="primary"
                     @click="$emit('submit', uploadedFile)">
                <CSpinner v-if="loading" size="sm"/>
                <span v-else> Submit </span>
            </CButton>
        </div>

    </div>

    <CModal class="overflow-modal" :visible="modals.validationRequestExtension" backdrop="static">
        <div class="app-modal upload-modal">
            <div class="app-modal__icon">
                <CIcon class="upload-modal-icon" name="cil-face-dead"/>
            </div>
            <h4 class="app-modal__title">File not supported!</h4>
            <p class="app-modal__text">
                Oops, this file format is not recognized yet by our platform.
                Here is the list of the supported formats: doc, docx, xlsx, xls, pdf, jpg, jpeg, gif, emp, svg, txt, png
            </p>
            <div class="app-modal__buttons">
                <CButton color="primary" @click="modals.validationRequestExtension = false">Got it!</CButton>
            </div>
        </div>
    </CModal>

    <CModal class="overflow-modal" :visible="modals.validationSize" backdrop="static">
        <div class="app-modal upload-modal">
            <div class="app-modal__icon">
                <CIcon class="upload-modal-icon" name="cil-face-dead"/>
            </div>
            <h4 class="app-modal__title">File not supported!</h4>
            <p class="app-modal__text">
                Oops, this file size is not supported yet by our platform. Maximum file size - 100 mb.
            </p>
            <div class="app-modal__buttons">
                <CButton color="primary" @click="modals.validationSize = false">Got it!</CButton>
            </div>
        </div>
    </CModal>

</template>

<script>

export default {
    name: "TaskPageDocumentFileArea",
    props: ['title', 'documentName', 'color', 'icon', 'upload', 'canDelete', 'loading'],
    data() {
        return {
            uploadedFile: null,
            uploadFileAreaHovered: false,
            validExtensions: {
                'Request documents': ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'jpg', 'jpeg', 'gif', 'emp', 'svg', 'txt', 'png'],
                'Contracts': ['pdf']
            },
            maxFileSize: 1e+8,
            modals: {
                validationRequestExtension: false,
                validationSize: false,
            }
        }
    },
    methods: {
        onFileChange(e) {
            this.uploadFileAreaHovered = false;

            if (this.validateFile(e.target.files[0])) {
                this.uploadedFile = e.target.files[0];
            }
        },
        validateFile(file) {
            if (!file) return false
            const fileExtension = file.name.split('.').pop();
            const isValidExtension = this.validExtensions['Request documents'].includes(fileExtension);
            const isValidSize = file.size < this.maxFileSize;

            if (!isValidExtension) {
                this.removeFileFromInputElement();
                this.modals.validationRequestExtension = true;
                return;
            }

            if (!isValidSize) {
                this.removeFileFromInputElement();
                this.modals.validationSize = true;
                return;
            }

            return true
        },
        removeFileFromInputElement() {
            const fileInputElement = this.$refs.fileUpload;
            if (fileInputElement) fileInputElement.value = '';
            this.uploadedFile = null;
        },
    }
}
</script>

<style scoped lang="scss">
.task-page-documents {

    & .upload-modal {
        width: 688px;

        &-icon {
            height: 80px !important;
            width: 80px !important;
            color: #BAC6CF;
        }

        &-text {
            font-size: 16px;
            color: #677A89;
        }

        &-list {
            font-size: 16px;
            color: #677A89;
            margin: 40px 0;
            padding: 0 20px;
        }
    }

    &__upload {

        &-link {
            cursor: pointer;
            text-decoration: underline;
            margin-left: 5px;
            color: rgba(0, 104, 173, 1);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &-input {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: none;
            opacity: 0;

            &-delete {
                cursor: pointer;
                margin-left: auto;
                right: 0;
            }

            &-placeholder {
                z-index: 1;

                &_inactive {
                    z-index: 0 !important;
                }
            }
        }
    }

    &__buttons {
        margin-top: 12px;
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        & button {
            min-width: 110px;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 16px;
        font-weight: 600;
        color: #1C262F;
        margin-bottom: 12px;

        & svg {
            color: #0068AD;
        }
    }

    &__area {
        display: flex;
        align-items: center;
        padding: 0 16px;
        min-height: 56px;
        border-radius: 8px;
        position: relative;

        & svg {
            flex-shrink: 0;
        }

        &_upload {
            color: rgba(103, 122, 137, 1);
            font-size: 16px;
        }

        &_green {
            border: 1px dashed #008a6e;
            background: rgba(159, 233, 218, 0.24);
        }

        &_blue {
            border: 1px dashed #00437A;
            background: rgba(171, 231, 250, 0.25);
        }
    }

    &__link {
        color: #0068AD;
        cursor: pointer;
        font-size: 16px;
        text-decoration: underline;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

}
</style>
