<template>
    <div class="task-page-wrapper">
        <transition name="fade">
            <div v-if="task">
                <TaskPage
                    :tick="tick"
                    :task="task"
                    :task-type="taskType"
                    :user-type="userType"
                    :can-upload="false"
                    :instructions="instructions"
                    @update="updateTask"
                    @formDirtyChange="$emit('formDirtyChange', $event)"></TaskPage>
            </div>
        </transition>

        <div v-if="!task" class="task-page-wrapper__loading">
            <CSpinner color="primary" />
        </div>
    </div>
</template>

<script>
import TaskPage from '@/components/TaskPages/TaskPage.vue';
import { TaskService } from '@/services/Task/TaskService';

export default {
    name: 'TaskPageWrapper',
    components: { TaskPage },
    props: {
        taskType: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            loading: false,
            task: null,
            userType: null,
            tick: 0,
        };
    },
    computed: {
        taskUpdateTicker() {
            return this.$store.getters.taskUpdateTicker;
        },
        instructions() {
            if (!this.taskType || !this.userType) return [];

            const payload = {
                Acknowledge: {
                    internal: ['Your sub has received the notification to complete this task'],
                    external: ['Please review and acknowledge the document below'],
                },
                Contract: {
                    internal: [
                        'Your sub has received the instructions to download, sign and send you back this contract.',
                        'Then, upload the signed contract.',
                    ],
                    external: [
                        'Download this contract.',
                        'Sign it or reach out back if you need some changes.',
                        'Then you can send it back via email to the sender.',
                    ],
                },
                Request: {
                    internal: [
                        'Approve or reject the provided document',
                        'If a document is rejected, please provide an explanation to your partner',
                    ],
                    external: ['You can download and use the attached sample.', 'Or you can upload your own document.'],
                },
            };

            return payload[this.taskType][this.userType];
        },
    },
    watch: {
        taskUpdateTicker() {
            this.fetchData();
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loading = true;
            this.$http.tasks
                .getTask({ params: { task_id: this.$route.params.task_id } })
                .then(async (res) => {
                    await TaskService.checkTypeAndRedirect(this.taskType, res.data.data.type, this.$route.params);
                    await this.$store.dispatch('fetchTaskPermissions', this.$route.params.task_id);
                    this.task = res.data.data;
                    this.userType = this.task.is_external ? 'external' : 'internal';
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        this.$router.push({ name: 'PermissionsError' });
                    }
                })
                .finally(() => (this.loading = false));
        },
        updateTask(updatedTask) {
            this.task = updatedTask;
            this.tick++;
        },
    },
};
</script>

<style lang="scss">
.task-page-wrapper__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 8px;
    padding: 40px;
    min-height: calc(100vh - 200px);
}
</style>
