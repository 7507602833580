<template>
  <div class="position-relative" :style="{ 'background': disabled ? 'white' : '' }">
    <DatePicker
      ref="picker"
      :model-value="modelValue"
      :masks="masks"
      :available-dates="{ start: start, end: end }"
      :popover="{ positionFixed: true }"
      @update:modelValue="onDateSelect"
    >
      <template #default="{ inputValue, togglePopover }">
        <img class="fl__icon" src="@/assets/images/due_date.png" alt="Due date" >
        <input
          readonly
          required
          :value="inputValue"
          :disabled="disabled"
          :placeholder="placeholder"
          class="fl__input-with-icon"
          @focus="togglePopover()"
          @blur="$emit('blur', $event.target.value)"
          @input="$emit('update:modelValue', $event.target.value)"
        />
        <label v-show="label" class="fl__label-with-icon">
          {{ label }}
          <sup v-if="required" :class="{ 'text-error': true }">*</sup>
        </label>
      </template>
    </DatePicker>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar'
export default {
  name: "FLCalendar",
  components: {
    DatePicker,
  },
  props: {
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    modelValue: String,
    variant: { type: String, default: 'border' },
    error: { type: String, default: '' },
    required: { type: Boolean, default: false },
    start: { type: Date, default: () => null },
    end: { type: Date, default: () => null },
  },
  data() {
    return {
      masks: {
        input: 'MM/DD/YYYY'
      }
    }
  },
  methods: {
    onDateSelect(value) {
      this.$emit('update:modelValue', value)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
